import React from 'react'
import './BusinessBody.scss'
import { ReactComponent as BusinessBody320  } from '../../../assets/business-body-320.svg'
import { ReactComponent as BusinessBody360  } from '../../../assets/business-body-360.svg'
import { ReactComponent as BusinessBody768  } from '../../../assets/business-body-768.svg'
import { ReactComponent as BusinessBody1024 } from '../../../assets/business-body-1024.svg'
import { ReactComponent as BusinessBody1440 } from '../../../assets/business-body-1440.svg'
import { ReactComponent as BusinessBody1920 } from '../../../assets/business-body-1920.svg'

const BusinessBody = () => {
    return (
        <section className='business-body'>
            <BusinessBody320 className='w320' />
            <BusinessBody360 className='w360' />
            <BusinessBody768 className='w768' />
            <BusinessBody1024 className='w1024' />
            <BusinessBody1440 className='w1440' />
            <BusinessBody1920 className='w1920' />
        </section>
    )
}

export default BusinessBody
