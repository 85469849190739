import React from 'react'
import { Routes, Route } from "react-router-dom"
import Home from '../pages/Home'
import Resources from '../pages/Resources'
import Success from '../pages/Success'
import Business from '../pages/Business'
import ScrollToTop from '../components/ScrollToTop'

const AppRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/unidades-de-negocio/" element={<Business />} />
                <Route path="/productos/" element={<Resources />} />
                <Route path="/casos-de-exito/" element={<Success />} />
            </Routes>
            <ScrollToTop />
        </>
    )
}

export default AppRouter
