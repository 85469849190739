import React from 'react'
import './Business.scss'
import RootLayout from '../../layouts/RootLayout'
import BusinessBody from '../../components/business/BusinessBody'
import Inicio from '../../components/home/Inicio/Inicio'

const Business = () => {
    return (
        <RootLayout>
            <Inicio version='business' />
            <BusinessBody />
        </RootLayout>
    )
}

export default Business