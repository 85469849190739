import React from 'react'
import './SuccessBody.scss'
import { ReactComponent as SuccessBody320  } from '../../../assets/success-body-320.svg'
import { ReactComponent as SuccessBody360  } from '../../../assets/success-body-360.svg'
import { ReactComponent as SuccessBody768  } from '../../../assets/success-body-768.svg'
import { ReactComponent as SuccessBody1024 } from '../../../assets/success-body-1024.svg'
import { ReactComponent as SuccessBody1440 } from '../../../assets/success-body-1440.svg'
import { ReactComponent as SuccessBody1920 } from '../../../assets/success-body-1920.svg'

const SuccessBody = () => {
    return (
        <section className='success-body'>
            <SuccessBody320 className='w320' />
            <SuccessBody360 className='w360' />
            <SuccessBody768 className='w768' />
            <SuccessBody1024 className='w1024' />
            <SuccessBody1440 className='w1440' />
            <SuccessBody1920 className='w1920' />
        </section>
    )
}

export default SuccessBody
