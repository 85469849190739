import React, { useState } from 'react'
import './RootLayout.scss'

import BurgerMenu from '../../components/BurgerMenu'
import { NavLink } from 'react-router-dom';

const RootLayout = (props: any) => {
    const { children } = props;

    const [toggleNav, setToggleNav] = useState("close")
    
    const onClickToggleNav = () => {
        if (toggleNav === "close") {
            setToggleNav("open")
        }
        else {
            setToggleNav("close")
        }
    }

    return (
        <div className='container dark'>
            <header>
                <div className='content'>
                    <a className='logo' href='/#inicio'>
                        <svg className='logo' width="221" height="60" viewBox="0 0 221 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M46.2014 13.6477V46.0287C42.7824 46.0287 40.0014 43.2572 40.0014 39.8286V13.6381H46.2014V13.6477Z"
                                fill="#E4E4E4" />
                            <path
                                d="M75.477 33.5905V46.0286H69.7151V33.5905C69.7151 29.0381 67.296 26.8667 63.6674 26.8667C60.0389 26.8667 57.6198 29.0476 57.6198 33.5905V40.2667C57.6198 43.4476 55.0389 46.0286 51.8579 46.0286V33.5905C51.8579 25.6 56.6008 21.4857 63.6674 21.4857C70.7341 21.4857 75.477 25.5619 75.477 33.5905Z"
                                fill="#E4E4E4" />
                            <path
                                d="M90.0017 46.6668C84.1922 46.6668 80.2207 43.4763 80.2207 36.8382V13.6477H85.9826V22.1144H94.0208V27.343H85.9826V36.8287C85.9826 40.0192 87.6303 41.2859 90.0017 41.2859H94.0208V46.6668H90.0017Z"
                                fill="#E4E4E4" />
                            <path
                                d="M96.9901 34.0763C96.9901 26.8192 102.314 21.4954 109.866 21.4954C117.419 21.4954 122.647 26.8192 122.647 34.0763V36.1049H103.047C103.819 39.5906 106.485 41.4287 109.876 41.4287C112.438 41.4287 114.333 40.7049 115.543 39.1525H121.685C119.895 43.7049 115.543 46.6573 109.876 46.6573C102.314 46.6668 96.9901 41.343 96.9901 34.0763ZM116.543 31.5144C115.619 28.324 113.009 26.724 109.866 26.724C106.619 26.724 104.104 28.3716 103.19 31.5144H116.543Z"
                                fill="#E4E4E4" />
                            <path
                                d="M127.688 48.6478H133.497C134.469 50.8764 136.793 51.8954 139.84 51.8954C144.097 51.8954 146.764 49.9145 146.764 44.343V42.2573C145.116 44.924 142.259 46.3716 138.678 46.3716C132.964 46.3716 126.869 42.543 126.869 34.0763C126.869 26.8192 132.193 21.4954 139.745 21.4954C147.297 21.4954 152.526 26.5335 152.526 34.2763V44.1525C152.526 53.3049 146.859 56.8859 139.84 56.8859C133.878 56.8764 129.183 54.3145 127.688 48.6478ZM146.754 34.0763C146.754 29.8668 143.945 26.8668 139.735 26.8668C135.526 26.8668 132.669 29.8668 132.669 34.0763C132.669 38.2859 135.526 41.0002 139.735 41.0002C143.945 41.0002 146.754 38.2859 146.754 34.0763Z"
                                fill="#E4E4E4" />
                            <path
                                d="M167.765 26.8668C164.956 26.8668 163.022 28.2668 163.022 32.143V46.0383H157.26V32.143C157.26 24.9335 161.517 21.4954 167.765 21.4954H172.508V26.8668H167.765Z"
                                fill="#E4E4E4" />
                            <path
                                d="M215.868 26.8668C213.059 26.8668 211.125 28.2668 211.125 32.143V46.0383H205.363V32.143C205.363 24.9335 209.62 21.4954 215.868 21.4954H220.611V26.8668H215.868Z"
                                fill="#E4E4E4" />
                            <path
                                d="M174.961 34.324C174.961 26.724 180.285 21.4954 187.885 21.4954C195.39 21.4954 200.619 26.5811 200.619 34.2763V46.0383H195.342V41.343C193.895 44.8764 190.647 46.6668 186.876 46.6668C181.057 46.6668 174.961 42.5049 174.961 34.324ZM194.857 34.0763C194.857 29.8668 192.047 26.8668 187.838 26.8668C183.628 26.8668 180.771 29.8668 180.771 34.0763C180.771 38.2859 183.628 41.2859 187.838 41.2859C192.047 41.2859 194.857 38.2859 194.857 34.0763Z"
                                fill="#E4E4E4" />
                            <path
                                d="M0 47.6191V20C0 8.95239 8.95239 0 20 0V7.61906C13.1619 7.61906 7.61906 13.1619 7.61906 20V40C7.61906 44.2096 4.20953 47.6191 0 47.6191Z"
                                fill="#4C58FA" />
                            <path
                                d="M0 60C11.0476 60 20 51.0476 20 40V12.3809C15.7905 12.3809 12.381 15.7904 12.381 19.9999V40C12.381 46.8381 6.8381 52.3809 0 52.3809V60Z"
                                fill="#4C58FA" />
                        </svg>
                    </a>
                    <nav className={`navigation ${toggleNav}`}>
                        <ul>
                            <li><NavLink to={'/'} onClick={onClickToggleNav}>Inicio</NavLink></li>
                            <li><NavLink to={'/unidades-de-negocio/'} onClick={onClickToggleNav}>Unidades de negocio</NavLink></li>
                            <li><NavLink to={'/productos/'} onClick={onClickToggleNav}>Nuestros Productos</NavLink></li>
                            <li><NavLink to={'/casos-de-exito/'} onClick={onClickToggleNav}>Casos de éxito</NavLink></li>
                            <li><a href="#contacto" onClick={onClickToggleNav}>Contacto</a></li>
                        </ul>
                        <svg width="221" height="60" viewBox="0 0 221 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M46.2014 13.6477V46.0287C42.7824 46.0287 40.0014 43.2572 40.0014 39.8286V13.6381H46.2014V13.6477Z"
                                fill="#E4E4E4" />
                            <path
                                d="M75.477 33.5905V46.0286H69.7151V33.5905C69.7151 29.0381 67.296 26.8667 63.6674 26.8667C60.0389 26.8667 57.6198 29.0476 57.6198 33.5905V40.2667C57.6198 43.4476 55.0389 46.0286 51.8579 46.0286V33.5905C51.8579 25.6 56.6008 21.4857 63.6674 21.4857C70.7341 21.4857 75.477 25.5619 75.477 33.5905Z"
                                fill="#E4E4E4" />
                            <path
                                d="M90.0017 46.6668C84.1922 46.6668 80.2207 43.4763 80.2207 36.8382V13.6477H85.9826V22.1144H94.0208V27.343H85.9826V36.8287C85.9826 40.0192 87.6303 41.2859 90.0017 41.2859H94.0208V46.6668H90.0017Z"
                                fill="#E4E4E4" />
                            <path
                                d="M96.9901 34.0763C96.9901 26.8192 102.314 21.4954 109.866 21.4954C117.419 21.4954 122.647 26.8192 122.647 34.0763V36.1049H103.047C103.819 39.5906 106.485 41.4287 109.876 41.4287C112.438 41.4287 114.333 40.7049 115.543 39.1525H121.685C119.895 43.7049 115.543 46.6573 109.876 46.6573C102.314 46.6668 96.9901 41.343 96.9901 34.0763ZM116.543 31.5144C115.619 28.324 113.009 26.724 109.866 26.724C106.619 26.724 104.104 28.3716 103.19 31.5144H116.543Z"
                                fill="#E4E4E4" />
                            <path
                                d="M127.688 48.6478H133.497C134.469 50.8764 136.793 51.8954 139.84 51.8954C144.097 51.8954 146.764 49.9145 146.764 44.343V42.2573C145.116 44.924 142.259 46.3716 138.678 46.3716C132.964 46.3716 126.869 42.543 126.869 34.0763C126.869 26.8192 132.193 21.4954 139.745 21.4954C147.297 21.4954 152.526 26.5335 152.526 34.2763V44.1525C152.526 53.3049 146.859 56.8859 139.84 56.8859C133.878 56.8764 129.183 54.3145 127.688 48.6478ZM146.754 34.0763C146.754 29.8668 143.945 26.8668 139.735 26.8668C135.526 26.8668 132.669 29.8668 132.669 34.0763C132.669 38.2859 135.526 41.0002 139.735 41.0002C143.945 41.0002 146.754 38.2859 146.754 34.0763Z"
                                fill="#E4E4E4" />
                            <path
                                d="M167.765 26.8668C164.956 26.8668 163.022 28.2668 163.022 32.143V46.0383H157.26V32.143C157.26 24.9335 161.517 21.4954 167.765 21.4954H172.508V26.8668H167.765Z"
                                fill="#E4E4E4" />
                            <path
                                d="M215.868 26.8668C213.059 26.8668 211.125 28.2668 211.125 32.143V46.0383H205.363V32.143C205.363 24.9335 209.62 21.4954 215.868 21.4954H220.611V26.8668H215.868Z"
                                fill="#E4E4E4" />
                            <path
                                d="M174.961 34.324C174.961 26.724 180.285 21.4954 187.885 21.4954C195.39 21.4954 200.619 26.5811 200.619 34.2763V46.0383H195.342V41.343C193.895 44.8764 190.647 46.6668 186.876 46.6668C181.057 46.6668 174.961 42.5049 174.961 34.324ZM194.857 34.0763C194.857 29.8668 192.047 26.8668 187.838 26.8668C183.628 26.8668 180.771 29.8668 180.771 34.0763C180.771 38.2859 183.628 41.2859 187.838 41.2859C192.047 41.2859 194.857 38.2859 194.857 34.0763Z"
                                fill="#E4E4E4" />
                            <path
                                d="M0 47.6191V20C0 8.95239 8.95239 0 20 0V7.61906C13.1619 7.61906 7.61906 13.1619 7.61906 20V40C7.61906 44.2096 4.20953 47.6191 0 47.6191Z"
                                fill="#E4E4E4" />
                            <path
                                d="M0 60C11.0476 60 20 51.0476 20 40V12.3809C15.7905 12.3809 12.381 15.7904 12.381 19.9999V40C12.381 46.8381 6.8381 52.3809 0 52.3809V60Z"
                                fill="#E4E4E4" />
                        </svg>
                    </nav>
                    <BurgerMenu onClick={setToggleNav} state={toggleNav} />
                </div>
            </header>

            <div className='panels'>
                {children}
            </div>

            <section id='contacto'>
                <div>
                    <h3>Oficina</h3>
                    <div>
                        <p>David/Chiriqui  República de Panamá</p>
                        <p>+507 730 78 82</p>
                    </div>
                </div>
                <div>
                    <h3>Contactar</h3>
                    <p>info@integraringenieria.com</p>
                </div>
            </section>

            <footer>
                <div className='blue-bar'></div>
                <div className='content'>
                    <svg width="221" height="60" viewBox="0 0 221 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M46.2014 13.6477V46.0287C42.7824 46.0287 40.0014 43.2572 40.0014 39.8286V13.6381H46.2014V13.6477Z"
                            fill="#000323" />
                        <path
                            d="M75.477 33.5905V46.0286H69.7151V33.5905C69.7151 29.0381 67.296 26.8667 63.6674 26.8667C60.0389 26.8667 57.6198 29.0476 57.6198 33.5905V40.2667C57.6198 43.4476 55.0389 46.0286 51.8579 46.0286V33.5905C51.8579 25.6 56.6008 21.4857 63.6674 21.4857C70.7341 21.4857 75.477 25.5619 75.477 33.5905Z"
                            fill="#000323" />
                        <path
                            d="M90.0017 46.6668C84.1922 46.6668 80.2207 43.4763 80.2207 36.8382V13.6477H85.9826V22.1144H94.0208V27.343H85.9826V36.8287C85.9826 40.0192 87.6303 41.2859 90.0017 41.2859H94.0208V46.6668H90.0017Z"
                            fill="#000323" />
                        <path
                            d="M96.9901 34.0763C96.9901 26.8192 102.314 21.4954 109.866 21.4954C117.419 21.4954 122.647 26.8192 122.647 34.0763V36.1049H103.047C103.819 39.5906 106.485 41.4287 109.876 41.4287C112.438 41.4287 114.333 40.7049 115.543 39.1525H121.685C119.895 43.7049 115.543 46.6573 109.876 46.6573C102.314 46.6668 96.9901 41.343 96.9901 34.0763ZM116.543 31.5144C115.619 28.324 113.009 26.724 109.866 26.724C106.619 26.724 104.104 28.3716 103.19 31.5144H116.543Z"
                            fill="#000323" />
                        <path
                            d="M127.688 48.6478H133.497C134.469 50.8764 136.793 51.8954 139.84 51.8954C144.097 51.8954 146.764 49.9145 146.764 44.343V42.2573C145.116 44.924 142.259 46.3716 138.678 46.3716C132.964 46.3716 126.869 42.543 126.869 34.0763C126.869 26.8192 132.193 21.4954 139.745 21.4954C147.297 21.4954 152.526 26.5335 152.526 34.2763V44.1525C152.526 53.3049 146.859 56.8859 139.84 56.8859C133.878 56.8764 129.183 54.3145 127.688 48.6478ZM146.754 34.0763C146.754 29.8668 143.945 26.8668 139.735 26.8668C135.526 26.8668 132.669 29.8668 132.669 34.0763C132.669 38.2859 135.526 41.0002 139.735 41.0002C143.945 41.0002 146.754 38.2859 146.754 34.0763Z"
                            fill="#000323" />
                        <path
                            d="M167.765 26.8668C164.956 26.8668 163.022 28.2668 163.022 32.143V46.0383H157.26V32.143C157.26 24.9335 161.517 21.4954 167.765 21.4954H172.508V26.8668H167.765Z"
                            fill="#000323" />
                        <path
                            d="M215.868 26.8668C213.059 26.8668 211.125 28.2668 211.125 32.143V46.0383H205.363V32.143C205.363 24.9335 209.62 21.4954 215.868 21.4954H220.611V26.8668H215.868Z"
                            fill="#000323" />
                        <path
                            d="M174.961 34.324C174.961 26.724 180.285 21.4954 187.885 21.4954C195.39 21.4954 200.619 26.5811 200.619 34.2763V46.0383H195.342V41.343C193.895 44.8764 190.647 46.6668 186.876 46.6668C181.057 46.6668 174.961 42.5049 174.961 34.324ZM194.857 34.0763C194.857 29.8668 192.047 26.8668 187.838 26.8668C183.628 26.8668 180.771 29.8668 180.771 34.0763C180.771 38.2859 183.628 41.2859 187.838 41.2859C192.047 41.2859 194.857 38.2859 194.857 34.0763Z"
                            fill="#000323" />
                        <path
                            d="M0 47.6191V20C0 8.95239 8.95239 0 20 0V7.61906C13.1619 7.61906 7.61906 13.1619 7.61906 20V40C7.61906 44.2096 4.20953 47.6191 0 47.6191Z"
                            fill="#4C58FA" />
                        <path
                            d="M0 60C11.0476 60 20 51.0476 20 40V12.3809C15.7905 12.3809 12.381 15.7904 12.381 19.9999V40C12.381 46.8381 6.8381 52.3809 0 52.3809V60Z"
                            fill="#4C58FA" />
                    </svg>
                    <p>©2023</p>
                </div>
            </footer>
        </div>
    )
}

export default RootLayout