import React from 'react'
import './Inicio.scss'

const Inicio = (props: any) => {

    if (props.version === 'business') {
        return (
            <section className='container' id='inicio'>
                <div className='title'>
                    <h1>
                        <span>Unidades </span>
                        <span>de <span style={{ color: "#4C58FA" }}>Negocio</span></span>
                    </h1>
                </div>
            </section>
        )
    }

    else if (props.version === 'products') {
        return (
            <section className='container' id='inicio'>
                <div className='title'>
                    <h1>
                        <span>Nuestros </span>
                        <span style={{ color: "#4C58FA" }}>Productos</span>
                    </h1>
                </div>
            </section>
        )
    }

    else if (props.version === 'success') {
        return (
            <section className='container' id='inicio'>
                <div className='title'>
                    <h1>
                        <span>Casos </span>
                        <span>de <span style={{ color: "#4C58FA" }}>éxito</span></span>
                    </h1>
                </div>
            </section>
        )
    }

    else {
        return (
            <section className='container' id='inicio'>
                <div className='title inicio'>
                    <h1>
                        <span><span style={{ color: "#4C58FA" }}>In</span>tegrar</span>
                        <span><span style={{ color: "#67B6FC" }}> In</span>geniería</span>
                    </h1>
                    <h4>Tu mejor aliado tecnológico</h4>
                </div>
                <a className='button' href='#contacto'>
                    <p>Contactar</p>
                    <svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 8H30.5" stroke="#F5F5FA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M23.5 1L30.5 8L23.5 15" stroke="#F5F5FA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </a>
            </section>
        )
    }
}

export default Inicio
