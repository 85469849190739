import React from 'react'
import './styles/App.scss'

import AppRouter from './router/AppRouter'

const App = () => {
    return (<AppRouter/>)
}

export default App
