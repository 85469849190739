import React, { useRef, useState } from 'react'
import './ResourcesBody.scss'
// import { ReactComponent as ResourcesBody320  } from '../../../assets/resources-body-320.svg'
import { ReactComponent as Advantech1320 } from '../../../assets/advantech-1-320.svg'
import { ReactComponent as Cards320 } from '../../../assets/cards-320.svg'
import { ReactComponent as Advantech3320 } from '../../../assets/advantech-3-320.svg'
import { ReactComponent as Eaton320 } from '../../../assets/eaton-320.svg'

// import { ReactComponent as ResourcesBody360  } from '../../../assets/resources-body-360.svg'
import { ReactComponent as Advantech1360 } from '../../../assets/advantech-1-360.svg'
import { ReactComponent as Cards360 } from '../../../assets/cards-360.svg'
import { ReactComponent as Advantech3360 } from '../../../assets/advantech-3-360.svg'
import { ReactComponent as Eaton360 } from '../../../assets/eaton-360.svg'

// import { ReactComponent as ResourcesBody768  } from '../../../assets/resources-body-768.svg'
import { ReactComponent as Advantech1768 } from '../../../assets/advantech-1-768.svg'
import { ReactComponent as Cards768 } from '../../../assets/cards-768.svg'
import { ReactComponent as Advantech3768 } from '../../../assets/advantech-3-768.svg'
import { ReactComponent as Eaton768 } from '../../../assets/eaton-768.svg'

// import { ReactComponent as ResourcesBody1024 } from '../../../assets/resources-body-1024.svg'
import { ReactComponent as Advantech11024 } from '../../../assets/advantech-1-1024.svg'
import { ReactComponent as Advantech31024 } from '../../../assets/advantech-3-1024.svg'
import { ReactComponent as Eaton1024 } from '../../../assets/eaton-1024.svg'

// import { ReactComponent as ResourcesBody1440 } from '../../../assets/resources-body-1440.svg'
import { ReactComponent as Advantech11440 } from '../../../assets/advantech-1-1440.svg'
import { ReactComponent as Advantech31440 } from '../../../assets/advantech-3-1440.svg'
import { ReactComponent as Eaton1440 } from '../../../assets/eaton-1440.svg'

// import { ReactComponent as ResourcesBody1920 } from '../../../assets/resources-body-1920.svg'
import { ReactComponent as Advantech11920 } from '../../../assets/advantech-1-1920.svg'
import { ReactComponent as Advantech31920 } from '../../../assets/advantech-3-1920.svg'
import { ReactComponent as Eaton1920 } from '../../../assets/eaton-1920.svg'

const ResourcesBody = () => {

    const [isDragging, setIsDragging] = useState(false)
    const [startX, setStartX] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)
    const contenedorRef = useRef<HTMLDivElement>(null)

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true)
        setStartX(e.pageX - (contenedorRef.current?.offsetLeft || 0))
        setScrollLeft(contenedorRef.current?.scrollLeft || 0)
    }

    const handleMouseLeave = () => {
        setIsDragging(false)
    }

    const handleMouseUp = () => {
        setIsDragging(false)
    }

    const handleMouseMove = (e: React.MouseEvent) => {
        
        if (!isDragging) return
        e.preventDefault()
        const x = e.pageX - (contenedorRef.current?.offsetLeft || 0)
        const walk = x - startX
        if (contenedorRef.current) {
            contenedorRef.current.scrollLeft = scrollLeft - walk
        }
    }

    return (
        <section className='resources-body'>

            <Advantech1320 className='w320' />
            <Advantech1360 className='w360' />
            <Advantech1768 className='w768' />
            <Advantech11024 className='w1024' />
            <Advantech11440 className='w1440' />
            <Advantech11920 className='w1920' />

            <div className='sectores-industriales'>
                <h2>Sectores Industriales</h2>
                <div 
                    ref={contenedorRef}
                    className={`cards ${isDragging ? 'active' : ''}`}
                    onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
                    <Cards320 className='w320' />
                    <Cards360 className='w360'/>
                    <Cards768 className='w768 w1024 w1440 w1920'/>
                </div>
            </div>
            <Advantech3320 className='w320' />
            <Advantech3360 className='w360' />
            <Advantech3768 className='w768' />
            <Advantech31024 className='w1024' />
            <Advantech31440 className='w1440' />
            <Advantech31920 className='w1920' />


            <Eaton320 className='w320' />
            <Eaton360 className='w360' />
            <Eaton768 className='w768' />
            <Eaton1024 className='w1024' />
            <Eaton1440 className='w1440' />
            <Eaton1920 className='w1920' />
            

            {/* <ResourcesBody1024 className='w1024' /> */}
            {/* <ResourcesBody1440 className='w1440' /> */}
            {/* <ResourcesBody1920 className='w1920' /> */}
        </section>
    )
}

export default ResourcesBody
