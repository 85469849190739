import React, { SetStateAction } from 'react'
import "./BurgerMenu.scss"

interface Props {
    onClick: (value: SetStateAction<string>) => void;
    state: string;
}

const BurgerMenu: React.FC<Props> = ({ onClick, state }) => {

    const burgerMenu = () => {
        if (state === "close") {
            onClick("open")
        }
        else {
            onClick("close")
        }
    }

    return (
        <div className={`bm-container ${state}`}  onClick={burgerMenu}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="bm-icon">
                <rect x="2" y="5" width="20" height="2" rx="1" fill="#E9E9F2" className="bm-line1"/>
                <rect x="2" y="11" width="20" height="2" rx="1" fill="#E9E9F2" className="bm-line2"/>
                <rect x="2" y="17" width="20" height="2" rx="1" fill="#E9E9F2" className="bm-line3"/>
            </svg>
        </div>
    )
}

export default BurgerMenu