import React from 'react'
import { useNavigate } from 'react-router-dom';
import './HomeBody.scss'
import { ReactComponent as HomeBody320  } from '../../../assets/home-body-320.svg'
import { ReactComponent as HomeBody360 } from '../../../assets/home-body-360.svg'
import { ReactComponent as HomeBody768  } from '../../../assets/home-body-768.svg'
import { ReactComponent as HomeBody1024 } from '../../../assets/home-body-1024.svg'
import { ReactComponent as HomeBody1440 } from '../../../assets/home-body-1440.svg'
import { ReactComponent as HomeBody1920 } from '../../../assets/home-body-1920.svg'


const HomeBody = () => {
    const navigate = useNavigate();

    const handleElementClick = (e: any) => {
        let targetElement = e.target;
        
        // Verificar si el elemento clickeado o alguno de sus ancestros tiene el ID deseado
        while (targetElement != null) {
            if (targetElement.id === 'tarjetas-soluciones') { // 'elementID' es el ID del elemento específico dentro del SVG
                navigate('/unidades-de-negocio/');
                return;
            }
            targetElement = targetElement.parentElement;
        }
    }

    return (
        <section className='home-body'>
            <HomeBody320 className='w320' onClick={handleElementClick} />
            <HomeBody360 className='w360' onClick={handleElementClick} />
            <HomeBody768 className='w768' onClick={handleElementClick} />
            <HomeBody1024 className='w1024' onClick={handleElementClick} />
            <HomeBody1440 className='w1440' onClick={handleElementClick} />
            <HomeBody1920 className='w1920' onClick={handleElementClick} />
        </section>
    )
}

export default HomeBody
