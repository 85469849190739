import React from 'react'
import './Home.scss'
import RootLayout from '../../layouts/RootLayout'
import Inicio from '../../components/home/Inicio'
import HomeBody from '../../components/home/HomeBody'

const Home = () => {
    return (
        <RootLayout>
            <Inicio />
            <HomeBody />
        </RootLayout>
    )
}

export default Home