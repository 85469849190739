import React from 'react'
import './Resources.scss'
import RootLayout from '../../layouts/RootLayout/RootLayout'
import Inicio from '../../components/home/Inicio'
import ResourcesBody from '../../components/resources/ResourcesBody'

const Resources = () => {
    return (
        <RootLayout>
            <Inicio version='products' />
            <ResourcesBody />
        </RootLayout>
    )
}

export default Resources