import React from 'react'
import './Success.scss'
import RootLayout from '../../layouts/RootLayout'
import Inicio from '../../components/home/Inicio'
import SuccessBody from '../../components/success/SuccessBody'

const Success = () => {
    return (
        <RootLayout>
            <Inicio version='success' />
            <SuccessBody />
        </RootLayout>
    )
}

export default Success